import { Box, Flex, Text } from '@chakra-ui/react';
import { ServiceCategoryTeasersProps } from '../types';
import { SectionHeader } from './BasicBlocks';
import ButtonLink from './ButtonLink';
import Heading from './Heading';
import Image from './Image';

const ServiceCategoryTeasers = ({
  title: heading,
  services,
}: ServiceCategoryTeasersProps) => {
  if (!services) {
    return null;
  }
  return (
    <Box as="section" data-test-id="section-service-category-teasers">
      {heading && <SectionHeader title={heading} pl={[5, 5, 0]} />}
      <Flex flexWrap="wrap" gridGap="5">
        {services?.map(({ title, color, icon, text, url, link_text }) => (
          <Box flex={['0 0 100%', null, null, 1]} key={title}>
            <Flex flexWrap="wrap" alignItems="center" mb={[3, null, null, 0]}>
              {icon && (
                <Image
                  width="110px"
                  height="110px"
                  containerProps={{ mr: [5, null, null, 0] }}
                  src={icon}
                />
              )}
              {title && (
                <Heading
                  as="h3"
                  variant="h3"
                  color={color}
                  my={3}
                  flex={{ lg: '0 0 100%' }}
                >
                  {title}
                </Heading>
              )}
            </Flex>
            {text && (
              <Text mb={5} textStyle="sm">
                {text}
              </Text>
            )}
            {url && (
              <ButtonLink
                variant="outlineGreen"
                link={{
                  url,
                  title: link_text,
                }}
              />
            )}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default ServiceCategoryTeasers;
